/* Cancel.css */

.cancel-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cancel-content {
    text-align: center;
  }
  
  .cancel-icon {
    font-size: 72px;
    color: #FF5733;
  }
  
  h1 {
    font-size: 36px;
    margin: 20px 0;
  }
  
  p {
    font-size: 18px;
  }
  