@font-face {
  font-family: 'Lato';
  src: url('font/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url('font/PlayfairDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.key_features {
  display: inline-flex !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

#readMoreContent {
  display: none;
}

br {
  display: block;
  content: "";
  border-bottom: 15px solid transparent;
}

.Note {
  color: red;
}

.rdrDateDisplayWrapper {
  display: none;
}

p {
  font-family: 'Lato', sans-serif;
}

h1 {
  font-family: 'PlayfairDisplay', sans-serif;
}

h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  font-weight: bold !important;
}

.nav-styles.active {
  color: black !important;
}

.width {
  width: 40% !important;
}

.label {
  text-align: left !important;
}

a{
    text-decoration: none !important;
    font-family: 'Lato', sans-serif;
}

html, body {
    height: 100%;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .nav-logo {
    width: 8rem;
    height: 8rem;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .content {
    flex: 1;
  }

  .nav-styles {
    padding: 0 !important;
    margin-right: 1rem;
  }
  
  .footer {
    margin-top: auto;
  }
  
  
