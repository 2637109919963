/* Info.css */

.info-container {
    padding: 40px;
  }
  
  .info-content {
    text-align: center;
  }
  
  .owner-image {
    max-width: 300px;
    margin: 20px 0;
  }
  
  .contact-info {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .contact-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  