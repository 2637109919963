.login-container {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
  }
  
  .register-link {
    text-decoration: none;
    color: #007bff !important;
    margin-top: 10px;
  }

  .custom-p {
    font-size: medium !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  